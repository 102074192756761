import React from "react";
import styled from "styled-components";

const FooterList = ({ children }) => {
  return <StyledList>{children}</StyledList>;
};

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 300;

  & > li {
    margin-bottom: 0.6rem;
  }
`;

export default FooterList;
