import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { mediaQueries } from "../theme/mediaQueries";

const Logo = ({ siteTitle, to }) => <StyledLogo to={to}>{siteTitle}</StyledLogo>;

const StyledLogo = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.logo};
  font-size: 1.4rem;
  font-weight: 200;
  line-height: 1;

  ${mediaQueries("sm")`
    font-size: 2rem;
  `}

  &::after {
    position: relative;
    height: 0;
  }
`;

export default Logo;
