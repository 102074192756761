import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';
import { RiSendPlaneFill } from 'react-icons/ri';
import FooterHeading from './FooterHeading';

const NewsletterSignUp = ({ footerNewsletterTitle, footerNewsletterText }) => {
  const [userEmail, setUserEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setUserEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await addToMailchimp(userEmail);
    setMessage(result.msg);
    setUserEmail('');
  };
  return (
    <section>
      <FooterHeading headingText={footerNewsletterTitle} />
      <p style={{ fontWeight: '300', marginBottom: '1rem' }}>{footerNewsletterText}</p>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <StyledForm id="newsletterForm" method="POST" onSubmit={handleSubmit}>
        <StyledInput
          type="email"
          name="email"
          placeholder="Email ..."
          value={userEmail}
          onChange={handleInputChange}
        />
        <StyledSubscribeBtn type="submit" form="newsletterForm">
          <RiSendPlaneFill size="1.5em" />
        </StyledSubscribeBtn>
      </StyledForm>
    </section>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
`;

const StyledInput = styled.input`
  background-color: rgba(255, 255, 255, 0.1);
  color: ${(props) => props.theme.colors.white};
  border: 0;
  padding: 0.3em 1em 0.3em 1em;
  width: calc(100% - (2em + 0.3em + 0.3em));
  line-height: 2em;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const StyledSubscribeBtn = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  height: calc(2em + 0.3em + 0.3em);
  width: calc(2em + 0.3em + 0.3em);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    & > svg {
      fill: ${(props) => props.theme.colors.black};
    }
  }
`;

export default NewsletterSignUp;
