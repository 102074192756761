import React from 'react';
import { Spotify, Youtubemusic, Deezer, Amazon, Applemusic } from '@icons-pack/react-simple-icons';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';

const FooterIconsListSection = ({
  footerListenNowTitle,
  spotify,
  youtube,
  itunes,
  highresaudio,
  deezer,
  amazon,
}) => {
  const streamingLinks = [
    { id: 1, name: 'Amazon Music', url: amazon, icon: <Amazon /> },
    { id: 2, name: 'Deezer', url: deezer, icon: <Deezer /> },
    { id: 3, name: 'Apple Music', url: itunes, icon: <Applemusic /> },
    { id: 4, name: 'Spotify', url: spotify, icon: <Spotify /> },
    { id: 5, name: 'YouTube Music', url: youtube, icon: <Youtubemusic /> },
    { id: 6, name: 'HighResAudio', url: highresaudio, icon: 'HRA' },
  ];
  const linkList = streamingLinks.map((slink) => {
    return (
      <a key={slink.id} href={slink.url} target="_blank">
        {slink.icon}
      </a>
    );
  });

  const listenTitleArray = footerListenNowTitle.split(' ');
  const largeTitle = listenTitleArray[0];
  const smallTitle = listenTitleArray.slice(1).join(' ');
  return (
    <StyledSocialSection>
      <StyledCTA>
        {largeTitle} <span>{smallTitle}</span>
      </StyledCTA>
      <StyledIconsList>{linkList}</StyledIconsList>
    </StyledSocialSection>
  );
};

const StyledCTA = styled.span`
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
  z-index: 2;
  margin-bottom: 1rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;

  & > span {
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
    padding-left: 0.6rem;
    text-transform: none;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 0;
    & > span {
      padding: 0 0.8rem;
    }
  }
`;

const StyledIconsList = styled.span`
  background-color: ${(props) => props.theme.colors.black};
  z-index: 2;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;

  & > a {
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      margin-left: 0.6em;
    }

    & > svg {
      width: 2rem;
      height: 2rem;
    }

    &:hover,
    &:active {
      & > svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

const StyledSocialSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.md}px) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      border-top: 1px solid ${(props) => props.theme.colors.white};
      width: calc(100% - 20px);
    }
  }
`;

export default FooterIconsListSection;
