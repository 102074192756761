import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../theme/mediaQueries';
import FooterHeading from './FooterHeading';
import PortableText from '../portableText';

const FooterInfo = ({ footerInfoTitle, footerText }) => {
  return (
    <section>
      <FooterHeading headingText={footerInfoTitle} />
      <StyledText>
        <PortableText blocks={footerText} />
      </StyledText>
    </section>
  );
};

const StyledText = styled.div`
  & p {
    font-weight: 300;
    text-align: center !important;

    ${mediaQueries('md')`
      text-align: left !important;
  `}

    & a {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 400;

      ::after {
        height: 0;
        width: 0;
      }
    }
  }
`;

export default FooterInfo;
