import React from "react";
import styled from "styled-components";

import { mediaQueries } from "../../theme/mediaQueries";

const SidebarToggler = ({ menuOpen }) => {
  return (
    <StyledLabel htmlFor="sidebar-checkbox" menuOpen={menuOpen}>
      <StyledIcon menuOpen={menuOpen}>
        <span />
        <span />
        <span />
      </StyledIcon>
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  position: absolute;
  background-color: #d2a24f;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 4.125rem;
  height: 4.125rem;
  z-index: 1040;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => (props.menuOpen ? "translateX(-100%)" : "translateX(0)")};
  transition: ${(props) => props.theme.transforms.transitionMenu};

  @media screen and (min-width: 360px) {
    transform: ${(props) => (props.menuOpen ? "translateX(-50%)" : "translateX(0)")};
  }
`;

const StyledIcon = styled.div`
  margin: 20px;
  width: 35px;
  height: 30px;
  margin: 18px 15.5px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  transform: ${(props) => (props.menuOpen ? "rotate(90deg)" : "rotate(0)")};

  & > span {
    background-color: #fff;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }
  & > span:nth-child(1) {
    width: ${(props) => (props.menuOpen ? "30px" : "100%")};
    height: 4px;
    display: block;
    top: ${(props) => (props.menuOpen ? "12px" : "0")};
    left: ${(props) => (props.menuOpen ? "3px" : "0")};
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transition-delay: 150ms;
    ${(props) => (props.menuOpen ? "transform: rotate(90deg)" : "transform: rotate(0)")};
  }
  & > span:nth-child(2) {
    width: ${(props) => (props.menuOpen ? "20px" : "100%")};
    height: 4px;
    display: block;
    top: ${(props) => (props.menuOpen ? "20px" : "13px")};
    left: ${(props) => (props.menuOpen ? "2px" : "0")};
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transition-delay: 50ms;
    ${(props) => (props.menuOpen ? "transform: rotate(45deg)" : "transform: rotate(0)")};
  }
  & > span:nth-child(3) {
    width: ${(props) => (props.menuOpen ? "20px" : "100%")};
    height: 4px;
    display: block;
    ${(props) => (props.menuOpen ? "top: 20px" : "bottom: 0")};
    left: ${(props) => (props.menuOpen ? "14px" : "0")};
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transition-delay: 100ms;
    ${(props) => (props.menuOpen ? "transform: rotate(-45deg)" : "transform: rotate(0)")};
  }
`;

export default SidebarToggler;
