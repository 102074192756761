import React from 'react';
import ReactPlayer from 'react-player';
import getYouTubeId from 'get-youtube-id';
import styled from 'styled-components';

const YoutubeEmbed = ({ url }) => {
  const id = getYouTubeId(url);

  return (
    <VideoWrapper className="youtube-in-text">
      <StyledPlayer url={`https://www.youtube.com/watch?v=${id}`} width="100%" height="100%" />
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export default YoutubeEmbed;
