import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

import LanguageSwitcher from '../LanguageSwitcher';

const Sidebar = React.forwardRef(
  ({ menuOpen, baseUrl, navLinks, defaultLang, lang, relPath, children }, ref) => {
    const menuItemsList = navLinks.map((link, i) => {
      // console.log(link);
      return (
        <StyledNavListItem key={i}>
          <StyledNavLink to={link.url} className="highlighted-link">
            {link.title[lang]}
          </StyledNavLink>
        </StyledNavListItem>
      );
    });

    return (
      <div ref={ref}>
        {children}
        <StyledSidebar menuOpen={menuOpen}>
          <StyledNavList>
            <StyledNavListItem>
              <StyledNavLink to={baseUrl} className="highlighted-link">
                home
              </StyledNavLink>
            </StyledNavListItem>

            {menuItemsList}
            <hr className="d-lg-none" />
            <StyledNavListItem>
              <LanguageSwitcher defaultLang={defaultLang} lang={lang} relPath={relPath} />
            </StyledNavListItem>
          </StyledNavList>
        </StyledSidebar>
      </div>
    );
  }
);

const StyledSidebar = styled.nav`
  backface-visibility: hidden;
  background-color: ${(props) => props.theme.colors.black};
  position: fixed;
  top: 0;
  bottom: 0;
  left: -20rem;
  width: 20rem;
  overflow-y: auto;
  z-index: 10;
  transform: ${(props) => (props.menuOpen ? 'translateX(20rem)' : 'translateX(0)')};
  transition: ${(props) => props.theme.transforms.transitionMenu};
`;
const StyledNavList = styled.ul`
  margin: 0;
  margin-left: 3rem;
  padding: 0;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledNavListItem = styled.li`
  display: block;
  padding: 0.6rem 0;

  &:last-of-type {
    ${mediaQueries('lg')`
      display: none;
      `}
  }
`;

const StyledNavLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 300;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 70%;
    height: 25%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    z-index: -1;
  }
`;

export default Sidebar;
