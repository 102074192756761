import React from "react";
import styled from "styled-components";

const FooterHeading = ({ headingText }) => {
  return <StyledHeading>{headingText}</StyledHeading>;
};

const StyledHeading = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;
`;

export default FooterHeading;
