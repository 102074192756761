import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const LanguageSwitcher = ({ defaultLang, lang, relPath }) => {
  if (lang === defaultLang) {
    return (
      <span>
        <LanguageLink to={`/en${relPath}`} className="d-lg-none">
          english version
        </LanguageLink>
        <LanguageLinkBtn to={`/en${relPath}`} className="d-none d-lg-inline-block">
          en
        </LanguageLinkBtn>
      </span>
    );
  } else {
    return (
      <span>
        <LanguageLink to={relPath} className="d-lg-none">
          german version
        </LanguageLink>
        <LanguageLinkBtn to={relPath} className="d-none d-lg-inline-block">
          de
        </LanguageLinkBtn>
      </span>
    );
  }
};

const LanguageLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
`;

const LanguageLinkBtn = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.transparent};
  font-size: 0.875rem;
  padding: 0.1rem 0.3rem;

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export default LanguageSwitcher;
