import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOnClickOutside } from '../lib/hooks';

import Sidebar from './Navigation/Sidebar';
import SidebarCheckbox from './Navigation/SidebarCheckbox';
import SidebarToggler from './Navigation/SidebarToggler';
import ContentWrapper from './ContentWrapper';
import Footer from './Footer/Footer';
import ConsentPopup from './ConsentPopup';

import Header from './header';
import LanguageSwitcher from './LanguageSwitcher';

const Layout = ({ children, pageContext, path, siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      navLinkBio: sanityPage(id: { eq: "-4b244c2b-81f1-566a-a75f-51f2c072d0af" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkEvents: sanityPage(id: { eq: "-63c5a03c-21f2-512e-81d7-e2961728aad4" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkBlog: sanityPage(id: { eq: "-d01d65c9-52f9-5731-8fc7-29b43fd70810" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkRecordings: sanityPage(id: { eq: "-bec422d0-6822-5b98-b1dc-abfd568abc82" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkPress: sanityPage(id: { eq: "-2b9d8163-c1f8-5a3b-845d-4de6425d6303" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkVideos: sanityPage(id: { eq: "-ff3dfa49-5532-56b2-b2cc-b5e0f8704552" }) {
        title {
          _type
          de
          en
        }
      }
      navLinkGallery: sanityPage(id: { eq: "-7bdafda6-3a42-590a-afdf-4ee93cd88586" }) {
        title {
          _type
          de
          en
        }
      }

      navLinkContact: sanityPage(id: { eq: "-badd5592-27a4-5b4f-aa03-dfb710f7beb8" }) {
        title {
          _type
          de
          en
        }
      }
    }
  `);

  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const defaultLang = 'de';

  const baseUrl = pageContext.locale === defaultLang ? '/' : '/en/';

  const {
    navLinkBio,
    navLinkEvents,
    navLinkBlog,
    navLinkRecordings,
    navLinkPress,
    navLinkVideos,
    navLinkGallery,
    navLinkContact,
  } = data;

  const allLanguageTitles = [
    navLinkBio,
    navLinkEvents,
    navLinkBlog,
    navLinkRecordings,
    navLinkPress,
    navLinkVideos,
    navLinkGallery,
    navLinkContact,
    // de: [
    //   'Biografie',
    //   'Konzerte',
    //   'Blog',
    //   'Aufnahmen',
    //   'Presse',
    //   'Videos',
    //   'Foto Galerie',
    //   'Kontakt',
    // ],
    // en: ['About', 'Events', 'Blog', 'Recordings', 'Press', 'Videos', 'Photo Gallery', 'Contact'],
  ];
  const allPageRoutes = [
    'about',
    'events',
    'blog',
    'recordings',
    'press',
    'videos',
    'gallery',
    'contact',
  ];
  // console.log(allLanguageTitles);

  const currentLangKey = pageContext.locale;

  // const currentLanguageTitles = allLanguageTitles[currentLangKey] || allLanguageTitles[defaultLang];

  // allLanguageTitles.map((item) => {
  //   console.log(item);
  // });
  const allNavigationLinks = allLanguageTitles.map((page, i) => {
    // console.log(page);
    return {
      // name: page,
      url: `${baseUrl}${allPageRoutes[i]}`,
      title: page.title,
    };
  });

  const getRelPath = (path) => {
    if (path.startsWith('/en')) {
      return path.replace('/en', '');
    } else {
      return path;
    }
  };

  return (
    <>
      <StyledOuterContainer>
        <Sidebar
          menuOpen={menuOpen}
          baseUrl={baseUrl}
          navLinks={allNavigationLinks}
          defaultLang={defaultLang}
          lang={currentLangKey}
          relPath={getRelPath(path)}
          ref={ref}
        >
          <SidebarCheckbox value={menuOpen} toggleMenu={handleToggleClick} />
        </Sidebar>

        <ContentWrapper menuOpen={menuOpen}>
          <SidebarToggler menuOpen={menuOpen} />
          <StyledSwitcherContainer className="d-none d-lg-inline-flex">
            <LanguageSwitcher
              defaultLang={defaultLang}
              lang={currentLangKey}
              relPath={getRelPath(path)}
            />
          </StyledSwitcherContainer>

          <Header siteTitle={siteTitle} baseUrl={baseUrl} />

          <StyledMain>{children}</StyledMain>

          <Footer lang={pageContext.locale} defaultLang={defaultLang} baseUrl={baseUrl} />
        </ContentWrapper>
      </StyledOuterContainer>
      <ConsentPopup lang={currentLangKey} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledOuterContainer = styled.div`
  overflow-x: hidden;
`;

const StyledSwitcherContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1050;
  height: calc(2rem + 2 * 2.125rem);
  padding-right: 2rem;
  justify-content: center;
  align-items: center;

  & > a {
    font-size: 0.875rem;
    font-weight: 300;
  }
`;

const StyledMain = styled.main`
  flex: 1 0 auto;
`;

export default Layout;
