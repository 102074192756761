import React from 'react';
import styled from 'styled-components';
import FooterHeading from './FooterHeading';
import FooterList from './FooterList';

const FooterSocial = ({ footerSocialTitle, facebook, youtube, email }) => {
  return (
    <section>
      <FooterHeading headingText={footerSocialTitle} />
      <FooterList>
        <li>
          <a href={facebook} target="_blank">
            Facebook
          </a>
        </li>
        <li>
          <a href={youtube} target="_blank">
            YouTube
          </a>
        </li>
        <li>
          <a href={`mailto:${email}?subject=Email%20von%20Website`}>Email</a>
        </li>
      </FooterList>
    </section>
  );
};

export default FooterSocial;
