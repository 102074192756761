import axios from 'axios';

export const sortByDate = (itemslist) => {
  // Convert publishedAt to Date
  itemslist.forEach((item) => {
    item.snippet.publishedAt = new Date(item.snippet.publishedAt);
  });
  // Sort results by publishedAt
  itemslist.sort(function (a, b) {
    return b.snippet.publishedAt - a.snippet.publishedAt;
  });
  return itemslist;
};

export const fetchData = async (didCancel, numVideos) => {
  let numberOfVideosToFetch;

  if (numVideos) {
    numberOfVideosToFetch = numVideos;
  } else {
    numberOfVideosToFetch = 25;
  }
  console.log(numVideos);

  // Youtube URL for fetching videos
  const url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=${numberOfVideosToFetch}&playlistId=${process.env.GATSBY_YOUTUBE_PLAYLIST_ID}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`;
  console.log(url);
  let fetchResult = {
    isLoading: true,
    isError: false,
    items: [],
  };

  try {
    if (!didCancel) {
      const result = await axios(url);

      fetchResult = {
        isLoading: true,
        isError: false,
        items: result.data.items,
      };
    }
  } catch (error) {
    if (!didCancel) {
      fetchResult = {
        isLoading: false,
        isError: true,
        items: [],
      };
    }
  }
  fetchResult.isLoading = false;

  return fetchResult;
};
