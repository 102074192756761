import React from "react";
import styled from "styled-components";

const ContentWrapper = ({ menuOpen, children }) => {
  return <StyledWrapper menuOpen={menuOpen}>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  transition: ${(props) => props.theme.transforms.transitionMenu};
  transform: ${(props) => (props.menuOpen ? "translateX(20rem)" : "translateX(0)")};
  z-index: 20;
`;

export default ContentWrapper;
