import React from "react";
import styled from "styled-components";

const SidebarCheckbox = ({ checked, toggleMenu }) => {
  return (
    <StyledCheckbox
      type="checkbox"
      id="sidebar-checkbox"
      checked={checked}
      onChange={e => toggleMenu(e)}
    />
  );
};

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  user-select: none;
`;

export default SidebarCheckbox;
