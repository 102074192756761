import { format, isFuture } from 'date-fns';

export function cn(...args) {
  return args.filter(Boolean).join(' ');
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`;
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export const localizeDate = (datetime, currentLang, size, withTime) => {
  let options;
  let formattedDatetimeObj;

  if (withTime) {
    options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hourCycle: 'h24',
      hour: '2-digit',
      minute: '2-digit',
    };

    formattedDatetimeObj = {
      de: new Date(datetime).toLocaleTimeString('de-DE', options),
      en: new Date(datetime).toLocaleTimeString('en-US', options),
    };
  } else {
    if (size && size === 'long') {
      options = { year: 'numeric', month: 'long', day: 'numeric' };

      formattedDatetimeObj = {
        de: new Date(datetime).toLocaleDateString('de-DE', options),
        en: new Date(datetime).toLocaleDateString('en-US', options),
      };
    } else {
      options = { year: 'numeric', month: 'short' };

      formattedDatetimeObj = {
        de: new Date(datetime).toLocaleDateString('de-DE', options),
        en: new Date(datetime).toLocaleDateString('en-US', options),
      };
    }
  }

  return formattedDatetimeObj[currentLang];
};

export const isEmail = (url) => {
  const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (mailFormat.test(url)) {
    return true;
  } else {
    return false;
  }
};
