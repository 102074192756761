import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import FooterHeading from './FooterHeading';
import FooterList from './FooterList';

const FooterQuickLinks = ({
  baseUrl,
  footerQuickLinksTitle,
  recordingsLink,
  eventsLink,
  blogLink,
  pressLink,
  videosLink,
}) => {
  return (
    <section>
      <FooterHeading headingText={footerQuickLinksTitle} />
      <FooterList>
        <li>
          <Link to={`${baseUrl}recordings`}>{recordingsLink}</Link>
        </li>
        <li>
          <Link to={`${baseUrl}events`}>{eventsLink}</Link>
        </li>
        <li>
          <Link to={`${baseUrl}blog`}>{blogLink}</Link>
        </li>
        <li>
          <Link to={`${baseUrl}press`}>{pressLink}</Link>
        </li>
        <li>
          <Link to={`${baseUrl}videos`}>{videosLink}</Link>
        </li>
      </FooterList>
    </section>
  );
};

export default FooterQuickLinks;
