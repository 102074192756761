import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

const FooterLegals = ({ lang }) => {
  const data = useStaticQuery(graphql`
    {
      gdprPage: sanityPage(id: { eq: "-74a65d69-b913-57db-ac85-e8e5a6cd90f4" }) {
        title {
          _type
          de
          en
        }
      }
      imprintPage: sanityPage(id: { eq: "-7f947bcd-0218-5290-873b-6a5e30cdae0b" }) {
        title {
          _type
          de
          en
        }
      }
    }
  `);
  return (
    <StyledFooterLegalsWrapper>
      <p>© {new Date().getFullYear()} Lars David Kellner</p>
      <p>
        <Link to={lang === 'en' ? `/${lang}/imprint/` : `/imprint/`}>
          {data.imprintPage.title.de && data.imprintPage.title.en
            ? data.imprintPage.title[lang]
            : 'Impressum'}
        </Link>{' '}
        |{' '}
        <Link to={lang === 'en' ? `/${lang}/gdpr/` : `/gdpr/`}>
          {data.gdprPage.title.de && data.gdprPage.title.en
            ? data.gdprPage.title[lang]
            : 'Datenschutzerklärung'}
        </Link>
      </p>
    </StyledFooterLegalsWrapper>
  );
};

const StyledFooterLegalsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-weight: 300;
  margin-top: 2rem;

  ${mediaQueries('md')`
    justify-content: space-between; 
  `}

  & > p {
    margin: 0;
    &:first-of-type {
      margin-right: 1rem;
    }
  }
`;

export default FooterLegals;
