import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { mapEdgesToNodes } from '../../lib/helpers';
import { mediaQueries } from '../../theme/mediaQueries';
import FooterLegals from './FooterLegals';
import NewsletterSignUp from './NewsletterSignUp';
import FooterInfo from './FooterInfo';
import FooterSocial from './FooterSocial';
import FooterQuickLinks from './FooterQuickLinks';
import FooterIconsListSection from './FooterIconsListSection';

const Footer = ({ lang, defaultLang, baseUrl }) => {
  const data = useStaticQuery(graphql`
    {
      blogPageTitle: sanityPage(id: { eq: "-d01d65c9-52f9-5731-8fc7-29b43fd70810" }) {
        title {
          _type
          de
          en
        }
      }
      eventsPageTitle: sanityPage(id: { eq: "-63c5a03c-21f2-512e-81d7-e2961728aad4" }) {
        title {
          _type
          de
          en
        }
      }
      recordingsPageTitle: sanityPage(id: { eq: "-bec422d0-6822-5b98-b1dc-abfd568abc82" }) {
        title {
          _type
          de
          en
        }
      }
      reviewsPageTitle: sanityPage(id: { eq: "-2b9d8163-c1f8-5a3b-845d-4de6425d6303" }) {
        title {
          _type
          de
          en
        }
      }
      videosPageTitle: sanityPage(id: { eq: "-ff3dfa49-5532-56b2-b2cc-b5e0f8704552" }) {
        title {
          _type
          de
          en
        }
      }
      sanityDictionaryFooter {
        footerSiteTitle
        footerQuickLinks {
          _type
          de
          en
        }
        footerSocialLinks {
          _type
          de
          en
        }
        footerNewsletterTitle {
          _type
          de
          en
        }
        footerNewsletterText {
          _type
          de
          en
        }
        footerListenNow {
          _type
          de
          en
        }
      }
      allSanityFooter(sort: { fields: _updatedAt, order: DESC }, limit: 1) {
        edges {
          node {
            id
            _rawFooterText
            email
            facebook
            youtube
            highresaudio
            itunes
            spotify
            deezer
            amazon
          }
        }
      }
    }
  `);

  const footerData = mapEdgesToNodes(data.allSanityFooter)[0];
  // console.log(data.aboutPage);

  const {
    email,
    facebook,
    youtube,
    highresaudio,
    itunes,
    spotify,
    deezer,
    amazon,
    _rawFooterText: footerText,
  } = footerData;

  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col xs={12} md={6} lg={5} className="mb-4 mb-lg-0">
            <FooterInfo
              footerInfoTitle={
                data.sanityDictionaryFooter.footerSiteTitle
                  ? data.sanityDictionaryFooter.footerSiteTitle
                  : 'Lars David Kellner'
              }
              footerText={lang === defaultLang ? footerText.de : footerText.en}
            />
          </Col>
          <Col xs={12} md={6} lg={2} className="mb-4 mb-lg-0">
            <FooterQuickLinks
              baseUrl={baseUrl}
              footerQuickLinksTitle={
                data.sanityDictionaryFooter.footerQuickLinks.de &&
                data.sanityDictionaryFooter.footerQuickLinks.en
                  ? data.sanityDictionaryFooter.footerQuickLinks[lang]
                  : 'Quick Links'
              }
              blogLink={
                data.blogPageTitle.title.de && data.blogPageTitle.title.en
                  ? data.blogPageTitle.title[lang]
                  : 'Blog'
              }
              eventsLink={
                data.eventsPageTitle.title.de && data.eventsPageTitle.title.en
                  ? data.eventsPageTitle.title[lang]
                  : 'Konzerte'
              }
              pressLink={
                data.reviewsPageTitle.title.de && data.reviewsPageTitle.title.en
                  ? data.reviewsPageTitle.title[lang]
                  : 'Presse'
              }
              recordingsLink={
                data.recordingsPageTitle.title.de && data.recordingsPageTitle.title.en
                  ? data.recordingsPageTitle.title[lang]
                  : 'Aufnahmen'
              }
              videosLink={
                data.videosPageTitle.title.de && data.videosPageTitle.title.en
                  ? data.videosPageTitle.title[lang]
                  : 'Videos'
              }
            />
          </Col>
          <Col xs={12} md={6} lg={2} className="mb-4 mb-lg-0">
            <FooterSocial
              facebook={facebook}
              youtube={youtube}
              email={email}
              footerSocialTitle={
                data.sanityDictionaryFooter.footerSocialLinks.de &&
                data.sanityDictionaryFooter.footerSocialLinks.en
                  ? data.sanityDictionaryFooter.footerSocialLinks[lang]
                  : 'Quick Links'
              }
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-4 mb-lg-0">
            <NewsletterSignUp
              footerNewsletterTitle={
                data.sanityDictionaryFooter.footerNewsletterTitle.de &&
                data.sanityDictionaryFooter.footerNewsletterTitle.en
                  ? data.sanityDictionaryFooter.footerNewsletterTitle[lang]
                  : 'Newsletter'
              }
              footerNewsletterText={
                data.sanityDictionaryFooter.footerNewsletterText.de &&
                data.sanityDictionaryFooter.footerNewsletterText.en
                  ? data.sanityDictionaryFooter.footerNewsletterText[lang]
                  : 'Newsletter'
              }
            />
          </Col>
        </Row>
        <Row className="pt-3 pt-md-5 mt-md-2">
          <FooterIconsListSection
            footerListenNowTitle={
              data.sanityDictionaryFooter.footerListenNow.de &&
              data.sanityDictionaryFooter.footerListenNow.en
                ? data.sanityDictionaryFooter.footerListenNow[lang]
                : 'Hören Sie rein'
            }
            spotify={spotify}
            youtube={youtube}
            itunes={itunes}
            highresaudio={highresaudio}
            deezer={deezer}
            amazon={amazon}
          />
        </Row>
        <Row className="pt-3 pt-md-5 mt-md-2">
          <FooterLegals lang={lang} />
        </Row>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: 3rem 0 1.5rem;
  background-color: ${(props) => props.theme.colors.black};
  text-align: center;

  & * {
    color: ${(props) => props.theme.colors.white};
  }

  ${mediaQueries('md')`
    text-align: left;
    padding: 3rem 0 1rem;
  `}
  ${mediaQueries('lg')`
    text-align: left;
    padding: 4rem 0 1rem;
  `}
`;

export default Footer;
