import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';

import { sortByDate } from './youtubeHelpers';

export const useLoadMore = (itemsList, numOfItemsToDisplay) => {
  // Set state for initially displayed list
  const [list, setList] = useState([...itemsList.slice(0, numOfItemsToDisplay)]);
  // Set state to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // Set state to determine if there is more to load
  const [hasMore, setHasMore] = useState(itemsList.length > numOfItemsToDisplay);

  // console.log('load more hook');

  useEffect(() => {
    // console.log('all available items: ', itemsList.length);
    // console.log('initially shown items: ', list.length);
    // console.log('loadMore', loadMore);
    // console.log('hasMore', hasMore);

    if (loadMore && hasMore) {
      const currentLength = list.length;
      // console.log(currentLength);
      const isMore = currentLength < itemsList.length;
      // console.log(isMore);
      const nextResults = isMore
        ? itemsList.slice(currentLength, currentLength + numOfItemsToDisplay)
        : [];
      // console.log(nextResults);
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    const isMore = list.length < itemsList.length;

    // console.log('currently displayed items: ', list.length);
    // console.log('items to show left', isMore);
    setHasMore(isMore);
  }, [list]);

  return [list, loadMore, hasMore, setLoadMore];
};

// Hook for closing Navigation on click outside Sidebar
export function useOnClickOutside(ref, callback) {
  const handleClick = (event) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    callback();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, callback]);
}
