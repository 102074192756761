import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

import PortableText from './portableText';

const ConsentPopup = ({ lang }) => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        _rawConsentText(resolveReferences: { maxDepth: 3 })
      }
      sanityDictionaryGeneral {
        consentOkButtonText {
          _type
          de
          en
        }
      }
      gdprPage: sanityPage(id: { eq: "-74a65d69-b913-57db-ac85-e8e5a6cd90f4" }) {
        title {
          _type
          de
          en
        }
      }
      imprintPage: sanityPage(id: { eq: "-7f947bcd-0218-5290-873b-6a5e30cdae0b" }) {
        title {
          _type
          de
          en
        }
      }
    }
  `);
  return (
    <CookieConsent
      location="bottom"
      buttonText={
        data.sanityDictionaryGeneral.consentOkButtonText.de &&
        data.sanityDictionaryGeneral.consentOkButtonText.en
          ? data.sanityDictionaryGeneral.consentOkButtonText[lang]
          : 'Sure man!!'
      }
      cookieName="myAwesomeCookieName2"
      style={{ background: '#191919', color: '#ffffff', alignItems: 'center', fontWeight: 300 }}
      buttonStyle={{ background: '#d2a24f', color: '#191919', fontSize: '1rem' }}
      expires={150}
    >
      {data.sanitySiteSettings._rawConsentText.de && data.sanitySiteSettings._rawConsentText.en ? (
        <PortableText blocks={data.sanitySiteSettings._rawConsentText[lang]} />
      ) : (
        'This website uses cookies to enhance the user experience.'
      )}
      <StyledConsentLink to={lang === 'en' ? `/${lang}/imprint/` : `/imprint/`}>
        {data.imprintPage.title.de && data.imprintPage.title.en
          ? data.imprintPage.title[lang]
          : 'Impressum'}
      </StyledConsentLink>{' '}
      |{' '}
      <StyledConsentLink to={lang === 'en' ? `/${lang}/gdpr/` : `/gdpr/`}>
        {data.gdprPage.title.de && data.gdprPage.title.en
          ? data.gdprPage.title[lang]
          : 'Datenschutzerklärung'}
      </StyledConsentLink>
    </CookieConsent>
  );
};

const StyledConsentLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default ConsentPopup;
