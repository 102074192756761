import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { mediaQueries } from "../theme/mediaQueries";

import Logo from "./Logo";

const Header = ({ siteTitle, baseUrl }) => (
  <StyledHeader>
    <Logo siteTitle={siteTitle} to={baseUrl} />
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 7.5vw 5vw;
  background-color: ${(props) => props.theme.colors.transparent};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1030;

  ${mediaQueries("sm")`
    padding: 2.125rem;
    justify-content: center;
  `}
`;

export default Header;
