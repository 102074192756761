import React from 'react';
import BasePortableText from '@sanity/block-content-to-react';

import { imageUrlFor } from '../lib/image-url';

import YoutubeEmbed from './Video/YoutubeEmbed';

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <h2>{props.children}</h2>;
        default:
          return <p>{props.children}</p>;
      }
    },
    imageOnly(props) {
      return (
        <figure>
          <img src={imageUrlFor(props.node.asset._ref).width(600).url()} alt="" />
        </figure>
      );
    },
    videoEmbed(props) {
      return <YoutubeEmbed url={props.node.url} />;
    },
  },
  marks: {
    link(props) {
      switch (props.mark._type) {
        case 'link':
          return (
            <a href={props.mark.href} target="_blank">
              {props.children}
            </a>
          );
      }
    },
  },
};

const PortableText = ({ blocks }) => (
  <BasePortableText blocks={blocks} serializers={serializers} className="portable-text" />
);

export default PortableText;
